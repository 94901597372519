import { graphql, Link } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'
import { SEO } from '../../components/seo'

const rowPadding = {
    padding: "100px 0"
}

export default function BlogPost({ data }) {
    const postData = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <section className="container" style={{ marginTop: "74px" }}>
                
                {
                    postData.slice(0,1).map(latestPost => (
                        <div className="row g-5 align-items-center text-white row-padding" key={latestPost.id} style={rowPadding}>
                            <div className="col-md-6">
                                <p className='text-primary text-uppercase' style={{ fontFamily: "GraphikSuper", letterSpacing: "1px"}}>Latest Blog Post</p>
                                <h1 className="display-5">{latestPost.frontmatter.title}</h1>
                                <p className="mb-3 text-white"><small>{latestPost.frontmatter.date}</small></p>
                                <p className="lead my-3">{latestPost.frontmatter.excerpt}...</p>
                                <p className="lead mb-0">
                                    <Link to={latestPost.frontmatter.slug} className="text-white fw-bold">
                                        Continue reading
                                    </Link>
                                </p>
                            </div>
                            <div className="col-md-6">
                                <GatsbyImage image={getImage(latestPost.frontmatter.featuredImage)} className="img-fluid w-100 h-100 rounded" alt={latestPost.frontmatter.title} />

                                
                            </div>
                        </div>
                    ))
                }

            </section>

            <section className='w-100 bg-white' id='blogs'>
                <div className='container'>
                    <div className="row text-dark row-padding" style={rowPadding}>
                        {
                            postData.slice(1).map(post => (
                                <div className="col-md-6 col-lg-4" key={post.id}>
                                    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                        <div className="col d-flex flex-column position-static" style={{height: "550px"}}>
                                            <div className="col-auto d-lg-block">
                                                <GatsbyImage image={getImage(post.frontmatter.featuredImage)} alt={post.frontmatter.title} style={{ width: "100%", height: "220px", objectFit: "cover" }} />
                                            </div>
                                            <div className='p-4'>
                                                <h2 className="h5 mb-2">{post.frontmatter.title}</h2>
                                                <p className="mb-3 text-muted"><small>{post.frontmatter.date}</small></p>
                                                <p className="card-text mb-2">{post.frontmatter.excerpt}...</p>
                                                <Link to={post.frontmatter.slug} key={post.id} className="stretched-link">
                                                    Continue reading
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const Head = () => (
    <SEO title="Blogs" />
)

export const query = graphql`
    query MyQuery {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    date(formatString: "MMM DD, YYYY")
                    slug
                    title
                    excerpt
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
                id
            }
        }
    }
  
`